import moment from 'moment';
import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  data: any;
};

// Default initial state
export const initialState = {
  loading: false,
  data: {},
};

const tableDialog = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'TABLE_DIALOG_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'TABLE_DIALOG_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

export default tableDialog;
