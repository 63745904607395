import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  list: any[];
  total?: number;
}

// Default initial state
export const initialState = {
  loading: false,
  list: [],
  total: 0,
};

const adminLog = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'ADMIN_LOG_SET_LIST':
      return {
        ...state,
        list: action.payload
      };
    case 'ADMIN_LOG_SET_UPDATE_LIST':
      const array = [...state.list, ...action.payload];
      return {
        ...state,
        list: array.filter((item1: any, index: number) => array.findIndex((item2: any) => item2.id === item1.id) === index),
      };
    case 'ADMIN_LOG_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'ADMIN_LOG_SET_TOTAL':
      return {
        ...state,
        total: action.payload,
      };
    default:
      return state;
  }
};

export default adminLog;
