import { useState } from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { NotificationType } from '../../types';

interface DispatchProps {
  removeSnackbar: (key: string) => void;
  removeCloseSnackbar: (key: string) => void;
};

interface StateProps {
  notifications: NotificationType[];
  closedNotifications: {
    key: string;
  }[];
};

type Props = DispatchProps & StateProps & WithSnackbarProps;

const Notifier = ({ notifications, enqueueSnackbar, closeSnackbar, removeSnackbar, closedNotifications, removeCloseSnackbar }: Props) => {
  const [displayed, setDisplayed] = useState<any[]>([]);

  const storeDisplayed = (key: any) => {
    setDisplayed([...displayed, key]);
  };

  notifications.forEach((notification:  any) => {
    setTimeout(() => {
      // If notification already displayed, abort
      if (displayed.indexOf(notification.key) > -1) return;
      // Display notification using notistack
      enqueueSnackbar(notification.message, notification.options);
      // Add notification's key to the local state
      storeDisplayed(notification.key);
      // Dispatch action to remove the notification from the redux store
      removeSnackbar(notification.key);
    }, 1);
  });
  
  closedNotifications.forEach((notification:  any) => {
    setTimeout(() => {
      // Display notification using notistack
      closeSnackbar(notification.key);
      // Dispatch action to remove the notification from the redux store
      removeCloseSnackbar(notification.key);
    }, 1);
  });

  return null;
};

export default withSnackbar(Notifier);
