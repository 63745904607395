import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxForm, FormStateMap } from 'redux-form';
import login, { State as LoginState } from './login/reducers';
import general, { State as GeneralState } from './general/reducers';
import collaborator, { State as CollaboratorState } from './collaborator/reducers';
import calendar, { State as CalendarState } from './calendar/reducers';
import collaboratorCalendar, { State as CollaboratorCalendarState } from './collaborator/calendar/reducers';
import administrator, { State as AdministratorState } from './administrator/reducers';
import office, { State as OfficeState } from './office/reducers';
import dayOffTypes, { State as DayOffTypesState } from './dayOffType/reducers';
import dayOff, { State as DayOffState } from './dayOff/reducers';
import area, { State as AreaState } from './area/reducers';
import reportDialog, { State as ReportDialogState } from './reportDialog/reducers';
import tableDialog, { State as TableDialogState } from './tableDialog/reducers';
import dailyClock, { State as DailyClockState } from './dailyClock/reducers';
import performanceDailyClock, { State as PerformanceDailyClockState } from './Performance/dailyClock/reducers';
import performanceDashboard, { State as PerformanceDashboard } from './Performance/dashboard/reducers';
import performanceTeam, { State as PerformanceTeam } from './Performance/team/reducers';
import performanceProfile, { State as PerformanceProfile } from './Performance/profile/reducers';
import performancePeople, { State as PerformancePeople } from './Performance/people/reducers';
import performanceReport, { State as PerformanceReport } from './Performance/report/reducers';
import log, { State as LogState } from './log/reducers';
import adminLog, { State as AdminLogState } from './adminLog/reducers';
import dailyClockNotification, {
  State as DailyClockNotificationState
} from './dailyClockNotification/reducers';
import randomDailyClockNotification, {
  State as RandomDailyClockNotificationState
} from './randomDailyClockNotification/reducers';
import companySettings, { State as CompanySettingsState } from './settings/reducers';
import company, { State as CompanyState } from './company/reducers';
import holiday, { State as HolidayState } from './holiday/reducers';
import nonWorkingDay, { State as NonWorkingDayState } from './nonWorkingDay/reducers';
import cafeteriaCalendar, { State as CafeteriaCalendarState } from './cafeteriaCalendar/reducers';

export interface RootState {
  login: LoginState;
  general: GeneralState;
  collaborator: CollaboratorState;
  administrator: AdministratorState;
  office: OfficeState;
  dayOffTypes: DayOffTypesState;
  dayOff: DayOffState;
  area: AreaState;
  reportDialog: ReportDialogState;
  tableDialog: TableDialogState;
  dailyClock: DailyClockState;
  performanceDailyClock: PerformanceDailyClockState;
  log: LogState;
  adminLog: AdminLogState;
  dailyClockNotification: DailyClockNotificationState;
  randomDailyClockNotification: RandomDailyClockNotificationState;
  form: FormStateMap;
  companySettings: CompanySettingsState;
  company: CompanyState;
  calendar: CalendarState;
  collaboratorCalendar: CollaboratorCalendarState;
  holiday: HolidayState;
  performanceDashboard: PerformanceDashboard;
  performanceTeam: PerformanceTeam;
  performanceProfile: PerformanceProfile;
  performancePeople: PerformancePeople;
  performanceReport: PerformanceReport;
  nonWorkingDay: NonWorkingDayState;
  cafeteriaCalendar: CafeteriaCalendarState;
}

const rootReducer = combineReducers<RootState>({
  login,
  general,
  collaborator,
  administrator,
  office,
  dayOffTypes,
  dayOff,
  area,
  reportDialog,
  tableDialog,
  dailyClock,
  performanceDailyClock,
  log,
  adminLog,
  dailyClockNotification,
  randomDailyClockNotification,
  form: reduxForm,
  company,
  calendar,
  collaboratorCalendar,
  companySettings,
  holiday,
  performanceDashboard,
  performanceTeam,
  performanceProfile,
  performancePeople,
  performanceReport,
  nonWorkingDay,
  cafeteriaCalendar,
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhacers(applyMiddleware(thunk)));

export default store;
