import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  data: any;
  todayData: any;
  areaData: any[];
}

// Default initial state
export const initialState = {
  loading: false,
  data: {},
  todayData: {},
  areaData: [],
};

const performanceTeam = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'PERFORMANCE_TEAM_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'PERFORMANCE_TEAM_SET_AREA_DROPDOWN_DATA':
      return {
        ...state,
        areaData: action.payload
      };
    case 'PERFORMANCE_TEAM_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    case 'PERFORMANCE_TEAM_SET_TODAY_DATA':
      return {
        ...state,
        todayData: action.payload
      };
    default:
      return state;
  }
};

export default performanceTeam;
