import { Action } from './actions';
import { NotificationType } from '../../types';

export type NotificationTypeWithKey = NotificationType & {
  key: string | number;
};

// States' definition
export interface State {
  open: boolean;
  openDashboardMenu: boolean;
  notifications: NotificationTypeWithKey[];
  closedNotifications: {
    key: string;
  }[];
}

// Default initial state
export const initialState = {
  open: true,
  openDashboardMenu: false,
  notifications: [],
  closedNotifications: [],
};

const general = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'GENERAL_SET_DRAWER':
      return {
        ...state,
        open: action.payload !== null && action.payload !== undefined ? action.payload : !state.open
      };
    case 'GENERAL_SET_DASHBOARD_MENU':
      return {
        ...state,
        openDashboardMenu:
          action.payload !== null && action.payload !== undefined
            ? action.payload
            : !state.openDashboardMenu
      };
    case 'GENERAL_ENQUEUE_SNACKBAR':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: new Date().getTime() + Math.random(),
            ...action.payload
          },
        ],
      };
    case 'GENERAL_REMOVE_SNACKBAR':
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.payload,
        ),
      };
    case 'GENERAL_CLOSE_SNACKBAR':
      return {
        ...state,
        closedNotifications: [
          ...state.closedNotifications,
          {
            key: action.payload,
          },
        ],
      };
    case 'GENERAL_REMOVE_CLOSE_SNACKBAR':
      return {
        ...state,
        closedNotifications: state.closedNotifications.filter(
          notification => notification.key !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default general;
