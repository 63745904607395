import axios from "axios";
import i18n from "i18next";
import { getFromStorage, removeFromStorage } from "./storage";
import moment from "moment";

axios.defaults.withCredentials = true;

const checkStatus = (response: any) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    throw new Error(response);
  }
};

interface OptionsProps {
  authorize?: boolean;
  withHeader?: boolean;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  data?: any;
  headers?: any;
  isFile?: boolean;
}

export const fetch = (url: string, options: OptionsProps) => {
  const languageFilter = `${url.indexOf("?") > -1 ? "&" : "?"}lang=${
    i18n.language
  }&tz=${moment().utcOffset()}`;
  const fetchOptions: any = {
    headers: {},
    ...options,
    url: `${process.env.REACT_APP_API}/${url}${languageFilter}`,
    method: options.method || "GET"
  };

  if (options.authorize) {
    fetchOptions.headers.Authorization = `Bearer ${getFromStorage("token")}`;
    options.authorize = undefined;
  }

  if (options.method === "POST" || options.method === "PUT") {
    if (options.isFile) {
      fetchOptions.data = options && options.data ? options.data : "";
    } else {
      fetchOptions.headers = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        ...fetchOptions.headers
      };
      fetchOptions.data =
        options && options.data ? JSON.stringify(options.data) : "";
    }
  }

  return axios(fetchOptions)
    .then(checkStatus)
    .then(response => (options.withHeader ? response : response.data))
    .catch(response => {
      if (
        response.response &&
        response.response.data &&
        response.response.data.code === "tokenInvalid"
      ) {
        removeFromStorage("token");
        removeFromStorage("company");
        removeFromStorage("settings");
        removeFromStorage("user");
        window.location.reload();
      }
      if (response.response && response.response.data) {
        throw new Error(response.response.data.message);
      } else {
        throw new Error(response);
      }
    });
};

export default fetch;
