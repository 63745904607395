import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  data: any[];
  total: number;
  dropdown: any[];
}

// Default initial state
export const initialState = {
  loading: false,
  data: [],
  dropdown: [],
  total: 0,
};

const performancePeople = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'PERFORMANCE_PEOPLE_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'PERFORMANCE_PEOPLE_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    case 'PERFORMANCE_PEOPLE_SET_TOTAL':
      return {
        ...state,
        total: action.payload,
      };
    case 'PERFORMANCE_PEOPLE_SET_DROPDOWN_DATA':
      return {
        ...state,
        dropdown: action.payload,
      }
    default:
      return state;
  }
};

export default performancePeople;
