import {HTML5_FMT} from 'moment';

export interface CONSTANT_TYPE {
  [key: string]: string;
}

export const ENTITIES: CONSTANT_TYPE = {
  AREA: 'Area',
  CALENDAR: 'Calendar',
  COMPANY: 'Company',
  COMPANY_LOCATION: 'Company Location',
  DAILY_CLOCK: 'Daily Clock',
  DAILY_CLOCK_NOTIFICATION: 'Daily Clock Notification',
  DAY_OFF: 'Day Off',
  DAY_OFF_TYPE: 'Day Off Type',
  DAY_OFF_UPLOAD: 'Day Off Upload',
  EMPLOYEE: 'Employee',
  EMPLOYEE_CALENDAR: 'Employee Calendar',
  INVOICE_TYPE: 'Invoice Type',
  LOG: 'Log',
  PERIOD: 'Period',
  PERMISSION: 'Permission',
  ROLE: 'Role',
  USER: 'User',
  CAFETERIA_CALENDAR: 'Cafeteria Calendar',
};

export const ACTIONS: CONSTANT_TYPE = {
  GET: 'GET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  IMPORT: 'IMPORT'
};

export const REPORT_TYPES: CONSTANT_TYPE = {
  ABSENCES: 'absences',
  DAILY_CLOCK: 'dailyClocks',
  EXTRA_HOURS: 'extraHours',
  CAFETERIA: 'cafeteria',
}

export const TIME_FORMAT_WITH_SECONDS: string = HTML5_FMT.TIME_SECONDS; // HH:mm:ss
export const TIME_FORMAT: string = 'HH.mm';
export const STANDARD_TIME_FORMAT: string = HTML5_FMT.TIME; // HH:mm
export const DATE: string = HTML5_FMT.DATE; // YYYY-MM-DD
export const DATE_FORMAT_DASH: string = 'DD-MM-YYYY';
export const DATE_FORMAT_HYPHENSE: string = 'DD/MM/YYYY';
export const DATETIME_FORMAT: string = `${DATE_FORMAT_DASH} ${HTML5_FMT.TIME}`; // DD/MM/YYYY HH:mm
export const DATETIME_FORMAT_SLASH: string = `${DATE_FORMAT_HYPHENSE} ${HTML5_FMT.TIME}`; // DD/MM/YYYY HH:mm
export const DATETIME_TIMEZONE_FORMAT: string = HTML5_FMT.DATETIME_LOCAL; // YYYY-MM-DDTHH:mm
