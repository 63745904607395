import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  list: any[];
  total?: number;
  data: any;
  filters?: string;
}

// Default initial state
export const initialState = {
  loading: false,
  total: 0,
  list: [],
  data: {},
  filters: '',
};

const collaborator = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'HOLIDAY_SET_LIST':
      return {
        ...state,
        list: action.payload
      };
    case 'HOLIDAY_UPDATE_LIST':
      const array = [...state.list, ...action.payload];
      return {
        ...state,
        list: array.filter(
          (item1: any, index: number) =>
            array.findIndex((item2: any) => item2.id === item1.id) === index
        )
      };
    case 'HOLIDAY_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'HOLIDAY_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    case 'HOLIDAY_ADD':
      return {
        ...state,
        data: action.payload,
        list: [...state.list, action.payload]
      };
    case 'HOLIDAY_UPDATE':
      return {
        ...state,
        data: action.payload,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload
            };
          }
          return item;
        })
      };
    case 'HOLIDAY_SET_TOTAL':
      return {
        ...state,
        total: action.payload
      };
    case 'HOLIDAY_SET_DELETE':
      return {
        ...state,
        data: action.payload
      };
    case 'HOLIDAY_SET_APPLIED_FILTERS':
      return {
        ...state,
        filters: action.payload
      };
    case 'HOLIDAY_SET_IMPORTS':
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

export default collaborator;
