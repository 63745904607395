import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

const estoyColor = '#d3ff82';

const theme = (props: any): ThemeOptions => ({
  palette: {
    type: 'dark',
    primary: {
      main: props && props.mainColor ? props.mainColor : estoyColor,
    },
    secondary: {
      main: '#000'
    },
    text: {
      primary: '#fff'
    },
    background: {
      default: '#2c2c2c'
    },
  },
  typography: {
    h1: {
      fontSize: 20
    }
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: props && props.mainColor ? props.mainColor : estoyColor,
        }
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: props && props.mainColor ? props.mainColor : estoyColor,
        },
        '&$checked&$disabled': {
          color: props && props.mainColor ? props.mainColor : estoyColor,
        }
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: props && props.mainColor ? props.mainColor : estoyColor,
          '& + $bar': {
            opacity: 0.5,
            backgroundColor: props && props.mainColor ? props.mainColor : estoyColor
          }
        }
      },
    },
    MuiButton: {
      outlined: {
        border: 'solid 2px',
        borderRadius: 33,
        textTransform: 'none',
        fontWeight: 'bold',
      },
      outlinedPrimary: {
        border: 'solid 2px',
        '&:hover': {
          border: 'solid 2px',
        }
      },
    },
    MuiExpansionPanel: {
      root: {
        width: '100%',
      },
    },
  },
});

export default theme;
