import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  data: any;
}

// Default initial state
export const initialState = {
  loading: false,
  data: {
    homeOfficeDays: [],
    inHouseDays: [],
    absences: [],
    delay: [],
    onTime: [],
  },
};

const performanceDashboard = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'PERFORMANCE_DASHBOARD_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'PERFORMANCE_DASHBOARD_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

export default performanceDashboard;
