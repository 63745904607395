import { Action } from './actions';
import { setOnStorage, removeFromStorage, getFromStorage } from '../../utils/storage';
import { LayoutType } from '../../types';

// States' definition
export interface State {
  loading?: boolean;
  token?: string;
  company?: string | number;
  settings?: any;
  user?: any;
  layout?: LayoutType;
  employee?: string | number;
}

// Default initial state
export const initialState = {
  loading: false,
  token: getFromStorage('token'),
  company: getFromStorage('company'),
  settings: getFromStorage('settings') || {},
  user: getFromStorage('user') || {},
  layout: getFromStorage('layout') || null,
  employee: getFromStorage('employee') || null,
};

const session = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'LOGIN_SET_SESSION':
      if (action.payload && action.payload.token && action.payload.token !== '') {
        setOnStorage('token', action.payload.token);
        setOnStorage('company', action.payload.company);
        setOnStorage('settings', action.payload.settings);
        setOnStorage('user', action.payload.user);
        return {
          ...state,
          token: action.payload.token,
          company: action.payload.company,
          settings: action.payload.settings,
          user: action.payload.user,
        };
      } else {
        removeFromStorage('token');
        removeFromStorage('company');
        removeFromStorage('settings');
        removeFromStorage('user');
        removeFromStorage('layout');
        return {
          ...state,
          token: undefined,
          company: undefined,
          settings: undefined,
          user: undefined,
          layout: undefined,
        };
      }
    case 'LOGIN_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'LOGIN_SET_LAYOUT':
      setOnStorage('layout', action.payload); // Dashboard o Administrator
      return {
        ...state,
        layout: action.payload,
      };
    case 'LOGIN_SET_EMPLOYEE':
      setOnStorage('employee', action.payload);
      return {
        ...state,
        employee: action.payload,
      };
    case 'LOGIN_SET_COMPANY_SETTINGS':
      setOnStorage('settings', action.payload);
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
};

export default session;
