import React from 'react';
import { Provider } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import store from './store';
import Notifier from './containers/notifier';
import theme from './themes';
import PublicRoutes from './routers/publicRoutes';

import './App.css';


const App = ({ t }: WithTranslation) => {
  const muiTheme = theme(store.getState().login.settings);
  return (
    <Provider store={store}>
      <ThemeProvider theme={createMuiTheme(muiTheme)}>
        <SnackbarProvider
          action={(key) => (
            <Button onClick={() => store.dispatch({
              type: 'GENERAL_CLOSE_SNACKBAR',
              payload: key,
            })}>
              {t('actions.dismiss')}
            </Button>
          )}
        >
          <PublicRoutes />
          <Notifier />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default withTranslation()(App);
