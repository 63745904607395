import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/privateRoute';
import GAListener from '../components/gaListener';
import Fallback from './fallback';
import { ENTITIES, ACTIONS } from '../constants';

const Terms = lazy(() => import('../containers/TermsContainer'));
const Login = lazy(() => import('../containers/LoginContainer'));
const LoginThirdparty = lazy(() => import('../containers/LoginThirdpartyContainer'));
const ForgotPassword = lazy(() => import('../containers/ForgotPasswordContainter'));
const ChangePassword = lazy(() => import('../containers/ChangePasswordContainer'));
const BasicLayout = lazy(() => import('../containers/BasicLayoutContainer'));
const PerformanceLayout = lazy(() => import('../containers/PerformanceLayout'));

const PublicRoutes = () => (
  <Router>
    <GAListener>
      <div id='App'>
        <Suspense fallback={<Fallback />}>
          <Switch>
            <Route path='/login' exact component={Login} />
            <Route path='/thirdparty' exact component={LoginThirdparty} />
            <Route path='/terms' exact component={Terms} />
            <Route path='/forgot_password' exact component={ForgotPassword} />
            <Route path='/change_password/:userId/:token' component={ChangePassword}/>
            <PrivateRoute path='/' exact component={BasicLayout} permissions={[]} withToken />
            <PrivateRoute path='/home' exact component={BasicLayout} permissions={[]} withToken />
            <PrivateRoute path='/admin' component={BasicLayout} permissions={[]} withToken />
            <PrivateRoute path='/performance' component={PerformanceLayout} permissions={[`${ENTITIES.DAILY_CLOCK}:${ACTIONS.GET}`]} withToken />
          </Switch>
        </Suspense>
      </div>
    </GAListener>
  </Router>
);

export default PublicRoutes;
