import { Action } from './actions';
import moment from 'moment';
import { TIME_FORMAT_WITH_SECONDS } from '../../constants';

// States' definition
export interface State {
  loading: boolean;
  list: any[];
  data: any;
  total?: number;
  filters?: string;
}

// Default initial state
export const initialState = {
  loading: false,
  list: [],
  total: 0,
  data: {
    minTimeNotification: moment(process.env.REACT_APP_DEFAULT_MIN_TIME_NOTIFICATION, TIME_FORMAT_WITH_SECONDS) || moment('00:30:00', TIME_FORMAT_WITH_SECONDS),
    maxTimeNotification: moment(process.env.REACT_APP_DEFAULT_MAX_TIME_NOTIFICATION, TIME_FORMAT_WITH_SECONDS) || moment('04:00:00', TIME_FORMAT_WITH_SECONDS),
    checkInTime: moment(process.env.REACT_APP_DEFAULT_CHECK_IN_TIME, TIME_FORMAT_WITH_SECONDS) || moment('00:10:00', TIME_FORMAT_WITH_SECONDS),
  },
  filters: '',
};

const company = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'COMPANY_SET_LIST':
      return {
        ...state,
        list: action.payload
      };
    case 'COMPANY_UPDATE_LIST':
      const array = [...state.list, ...action.payload];
      return {
        ...state,
        list: array.filter((item1: any, index: number) => array.findIndex((item2: any) => item2.id === item1.id) === index),
      };
    case 'COMPANY_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'COMPANY_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    case 'COMPANY_ADD':
      return {
        ...state,
        data: action.payload,
        list: [...state.list, action.payload],
      };
    case 'COMPANY_UPDATE':
      return {
        ...state,
        data: action.payload,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      };
    case 'COMPANY_SET_TOTAL':
      return {
        ...state,
        total: action.payload,
      };
    case 'COMPANY_SET_DELETE':
      return {
        ...state,
        data: action.payload,
      };
    case 'COMPANY_SET_APPLIED_FILTERS':
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};

export default company;
