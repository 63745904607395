import { NotificationType } from '../../types';

export interface SetDrawer {
  type: 'GENERAL_SET_DRAWER';
  payload: boolean;
};

export interface SetDashboardMenu {
  type: 'GENERAL_SET_DASHBOARD_MENU';
  payload: boolean;
};

export interface SetRemoveSnackbar {
  type: 'GENERAL_REMOVE_SNACKBAR';
  payload: string;
};

export interface SetCloseSnackbar {
  type: 'GENERAL_CLOSE_SNACKBAR';
  payload: string;
};

export interface SetRemoveCloseSnackbar {
  type: 'GENERAL_REMOVE_CLOSE_SNACKBAR';
  payload: string;
};

export interface SetEnqueueSnackbar {
  type: 'GENERAL_ENQUEUE_SNACKBAR';
  payload: NotificationType;
};

// Union Action Types
export type Action =
  | SetDrawer
  | SetRemoveSnackbar
  | SetEnqueueSnackbar
  | SetCloseSnackbar
  | SetRemoveCloseSnackbar
  | SetDashboardMenu;

export const toggleDrawer = (open?: boolean) => (dispatch: Function) => {
  dispatch({
    type: 'GENERAL_SET_DRAWER',
    payload: open
  });
};

export const toggleDashboardMenu = (openDashboardMenu?: boolean) => (dispatch: Function) => {
  dispatch({
    type: 'GENERAL_SET_DASHBOARD_MENU',
    payload: openDashboardMenu
  });
};

export const removeSnackbar = (key: string) => (dispatch: Function) => {
  dispatch({
    type: 'GENERAL_REMOVE_SNACKBAR',
    payload: key,
  });
};

export const closeSnackbar = (key: string) => (dispatch: Function) => {
  dispatch({
    type: 'GENERAL_CLOSE_SNACKBAR',
    payload: key,
  });
};

export const removeCloseSnackbar = (key: string) => (dispatch: Function) => {
  dispatch({
    type: 'GENERAL_REMOVE_CLOSE_SNACKBAR',
    payload: key,
  });
};

export const enqueueSnackbar = (notification: NotificationType) => (dispatch: Function) => {
  dispatch({
    type: 'GENERAL_ENQUEUE_SNACKBAR',
    payload: notification,
  });
};
