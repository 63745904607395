import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  data: any;
  list: any;
  filters?: string;
}

// Default initial state
export const initialState = {
  loading: false,
  data: {},
  list: [],
  filters: '',
};

const calendar = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'COLLABORATOR_CALENDAR_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'COLLABORATOR_CALENDAR_SET_DATA':
      return {
        ...state,
        data: action.payload,
        list: {}
      };
    case 'COLLABORATOR_CALENDAR_ADD':
      return {
        ...state,
        data: action.payload,
        list: [...state.list, action.payload],
      };
    case 'COLLABORATOR_CALENDAR_UPDATE':
      return {
        ...state,
        data: action.payload,
        // list: state.list.map(item => {
        //   if (item.id === action.payload.id) {
        //     return {
        //       ...item,
        //       ...action.payload,
        //     };
        //   }
        //   return item;
        // }),
      };
    case 'COLLABORATOR_CALENDAR_SET_DELETE':
      return {
        ...state,
        data: action.payload,
      };
    case 'COLLABORATOR_CALENDAR_SET_APPLIED_FILTERS':
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};

export default calendar;
