import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import en from './en';
import es from './es';
import { getFromStorage } from '../utils/storage';

const fallbackLng = 'en';
const resources = { en, es };
const browserLanguage: string = ((navigator.languages && navigator.languages[0]) || navigator.language).split('-')[0];

const companySettings = getFromStorage('settings');
const companyLanguage = companySettings && companySettings.language ? companySettings.language : fallbackLng;
const lng = Object.keys(resources).indexOf(browserLanguage) > -1 ? browserLanguage : companyLanguage;

i18n
  .use(initReactI18next)
  .init({
    fallbackLng,
    lng,
    resources,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

moment.locale(lng === 'en' ? 'en-gb' : 'es', {
  week: {
    dow: 0,
    doy: 6,
  },
});

export default i18n;
