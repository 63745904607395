import jwtDecode from 'jwt-decode';
import axiosFetch from '../../utils/request';
// import { setDefaultLanguage } from '../../translations';
import { ACTIONS, ENTITIES } from '../../constants';
import { LayoutType } from '../../types';
import i18n from '../../translations';

export interface SetSession {
  type: 'LOGIN_SET_SESSION';
  payload?: {
    token: string;
    company?: number | string;
    settings?: any;
    user?: any;
  };
}

export interface SetLoading {
  type: 'LOGIN_SET_LOADING';
  payload: boolean;
}

export interface SetLayout {
  type: 'LOGIN_SET_LAYOUT';
  payload: LayoutType;
}

export interface SetEmployee {
  type: 'LOGIN_SET_EMPLOYEE';
  payload: number | string;
}

export interface SetCompanySettings {
  type: 'LOGIN_SET_COMPANY_SETTINGS';
  payload: any;
};

// Union Action Types
export type Action = SetSession | SetLoading | SetCompanySettings | SetLayout | SetEmployee;

export const login = (id: string, password: string, company?: string) => (dispatch: Function) => {
  dispatch({
    type: 'LOGIN_SET_LOADING',
    payload: true
  });
  return axiosFetch('admin/login', {
    method: 'POST',
    data: {
      id,
      password,
      company
    }
  })
    .then(response => {
      dispatch({
        type: 'LOGIN_SET_SESSION',
        payload: response
      });
      dispatch({
        type: 'LOGIN_SET_LOADING',
        payload: false
      });
      if (response && response.token && response.token !== '') {
        const decoded: any = jwtDecode(response.token);
        const layout: LayoutType | undefined = 
          response.company === 'ALL'
            ? 'admin'
            : (
            !decoded.permissions.includes(`${ENTITIES.COMPANY}:${ACTIONS.UPDATE}:${response.company}`)
              ? 'dashboard'
              : undefined
            );
        dispatch({
          type: 'LOGIN_SET_LAYOUT',
          payload: layout,
        });
        dispatch({
          type: 'LOGIN_SET_EMPLOYEE',
          payload: decoded.employee,
        });
        window.location.reload();
      }
    })
    .catch(response => {
      const error = response.message || response.toJSON().message;
      dispatch({
        type: 'LOGIN_SET_LOADING',
        payload: false
      });
      dispatch({
        type: 'GENERAL_ENQUEUE_SNACKBAR',
        payload: {
          message: error,
          options: {
            variant: 'error'
          }
        }
      });
    });
};

export const logout = () => (dispatch: Function) => {
  dispatch({
    type: 'LOGIN_SET_LOADING',
    payload: true
  });
  dispatch({
    type: 'LOGIN_SET_SESSION',
  });
  dispatch({
    type: 'LOGIN_SET_LOADING',
    payload: false
  });
  window.location.reload();
};

export const updateLayout = (layout: LayoutType, fromLogin: boolean = false) => (dispatch: Function) => {
  dispatch({
    type: 'LOGIN_SET_LAYOUT',
    payload: layout
  });
  if (fromLogin) window.location.reload();
};

export const forgotPassword = (id: string) => (dispatch: Function) => {
  dispatch({
    type: 'LOGIN_SET_LOADING',
    payload: true
  });
  return axiosFetch('admin/forgot_password', {
    method: 'POST',
    withHeader: true,
    data: {
      nationalId: id,
    }
  })
    .then(response => {
      dispatch({
        type: 'LOGIN_SET_LOADING',
        payload: false
      });
      dispatch({
        type: 'GENERAL_ENQUEUE_SNACKBAR',
        payload: {
          message: i18n.t('login.recoverPasswordEmailSent'),
          options: {
            variant: 'success'
          }
        }
      });
    })
    .catch(response => {
      const error = response.message || response.toJSON().message;
      dispatch({
        type: 'LOGIN_SET_LOADING',
        payload: false
      });
      dispatch({
        type: 'GENERAL_ENQUEUE_SNACKBAR',
        payload: {
          message: error,
          options: {
            variant: 'error'
          }
        }
      });
    });
};

export const changePassword = (userId: string, token: string, password: string) => (dispatch: Function) => {
  dispatch({
    type: 'LOGIN_SET_LOADING',
    payload: true
  });
  return axiosFetch(`admin/change_password/${userId}/${token}`, {
    method: 'POST',
    withHeader: true,
    data: {
      password,
    }
  })
    .then(response => {
      dispatch({
        type: 'LOGIN_SET_LOADING',
        payload: false
      });
      dispatch({
        type: 'GENERAL_ENQUEUE_SNACKBAR',
        payload: {
          message: i18n.t('login.changePasswordSubmitted'),
          options: {
            variant: 'success'
          }
        }
      });
    })
    .catch(response => {
      const error = response.message || response.toJSON().message;
      dispatch({
        type: 'LOGIN_SET_LOADING',
        payload: false
      });
      dispatch({
        type: 'GENERAL_ENQUEUE_SNACKBAR',
        payload: {
          message: error,
          options: {
            variant: 'error'
          }
        }
      });
    });
};
