import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  data: any;
  total: number;
  allData: any;
}

// Default initial state
export const initialState = {
  loading: false,
  data: [],
  total: 0,
  allData: [],
};

const performanceReport = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'PERFORMANCE_REPORT_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'PERFORMANCE_REPORT_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    case 'PERFORMANCE_REPORT_SET_ALL_DATA':
      return {
        ...state,
        allData: action.payload
      };
    case 'PERFORMANCE_REPORT_SET_TOTAL':
      return {
        ...state,
        total: action.payload,
      };
    default:
      return state;
  }
};

export default performanceReport;
