import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { removeSnackbar, removeCloseSnackbar } from '../../store/general/actions';
import { RootState } from '../../store';
import Notifier from '../../components/notifier';
import { NotificationType } from '../../types';

interface DispatchProps {
  removeSnackbar: (key: string) => void;
  removeCloseSnackbar: (key: string) => void;
}

interface StateProps {
  notifications: NotificationType[];
  closedNotifications: {
    key: string;
  }[];
}

const mapStateToProps = (states: RootState): StateProps => ({
  notifications: states.general.notifications,
  closedNotifications: states.general.closedNotifications,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
  removeSnackbar: (key: string) => dispatch(removeSnackbar(key)),
  removeCloseSnackbar: (key: string) => dispatch(removeCloseSnackbar(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
