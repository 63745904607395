import { Action } from './actions';

// States' definition
export interface State {
  loading: boolean;
  data: any;
}

// Default initial state
export const initialState = {
  loading: false,
  data: {}
};

const companySettings = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'SETTINGS_SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'SETTINGS_SET_UPDATE':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        },
      }
    case 'SETTINGS_SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

export default companySettings;
