import { Action } from './actions';
import { SelectType } from '../../types';

// States' definition
export interface State {
  loading: boolean;
  list: any[];
  data: any;
  dropdownList: SelectType[];
}

// Default initial state
export const initialState = {
  loading: false,
  list: [],
  data: {},
  dropdownList: [],
};

const dayOffTypes = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'DAY_OFF_TYPE_SET_LIST':
      return {
        ...state,
        list: action.payload,
      };
    case 'DAY_OFF_TYPE_SET_DROPDOWN_LIST':
      return {
        ...state,
        dropdownList: action.payload,
      };
    case 'DAY_OFF_TYPE_SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'DAY_OFF_TYPE_SET_DATA':
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default dayOffTypes;
