import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { LayoutType, NotificationType } from '../../types';
import { updateLayout } from '../../store/login/actions';
import { enqueueSnackbar } from '../../store/general/actions';

interface OwnProps {
  component: React.ComponentType<any>;
  permissions: string[];
  path: string;
  exact?: boolean;
  withToken?: boolean;
  superadmin?: boolean;
}

interface PrivateRouteStateProps {
  company?: number | string;
  layout?: LayoutType;
  token?: string;
}

interface PrivateRouteDispatchProps {
  updateLayout: (layout: LayoutType) => void;
  showSnackbar: (notification: NotificationType) => void;
}

type Props = OwnProps & PrivateRouteStateProps & PrivateRouteDispatchProps & WithTranslation;

const PrivateRoute = ({
  component: Component,
  permissions,
  path,
  withToken,
  superadmin,
  company,
  layout,
  updateLayout,
  showSnackbar,
  token,
  t,
  ...rest
}: Props) => {
  let hasPermissions: boolean = false;
  if (token) {
    const now: number = Date.now() / 1000;
    const decoded: any = jwtDecode(token);
    if (decoded.exp >= now) {
      if (superadmin && company === 'ALL') {
        hasPermissions = true;
      } else {
        hasPermissions = permissions.some((permission: string) =>
          decoded.permissions.includes(`${permission}:${company}`)
        );
        if (path.includes('/performance') && layout === 'dashboard' && !hasPermissions) {
          updateLayout('admin');
          showSnackbar({
            message: t('login.panelPermissionsDenied'),
            options: {
              variant: 'error'
            }
          });
        }
      }
    }
  }
  if (withToken && token) {
    hasPermissions = true;
  }

  return (
    <Route
      path={path}
      {...rest}
      render={props => (hasPermissions ? <Component {...props} /> : <Redirect to='/login' />)}
    />
  );
};

const mapStateToProps = (states: RootState): PrivateRouteStateProps => ({
  layout: states.login.layout,
  company: states.login.company,
  token: states.login.token,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): PrivateRouteDispatchProps => ({
  updateLayout: (layout: LayoutType) => dispatch(updateLayout(layout)),
  showSnackbar: (notification: NotificationType) => dispatch(enqueueSnackbar(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PrivateRoute));
