import 'moment/locale/es';

export default {
  translation: {
    actions: {
      actions: 'Acciones',
      save: 'Guardar',
      clear: 'Limpiar campos',
      edit: 'Editar',
      create: 'Crear',
      cancel: 'Cancelar',
      dismiss: 'Cerrar',
      unlinkDevice: 'Blanquear dispositivo',
      disenroll: 'Desenrolar',
      delete: 'Borrar',
      confirmDelete: 'Confirmar borrado',
      confirmDeleteRow: '¿Deseas eliminar esta fila?',
      accept: 'Aceptar',
      search: 'Buscar',
    },
    types: {
      false: 'Falso',
      true: 'Verdadero'
    },
    home: {
      title: '¡Bienvenido!'
    },
    login: {
      button_login: 'Ingresar',
      document_id: 'Número de documento',
      password: 'Contraseña',
      repeatPassword: 'Repetir contraseña',
      company_code: 'Código de compañía',
      userAccount: 'Cuenta de usuario logueado',
      dashboard: 'Panel de performance',
      panelPermissionsDenied: 'No tiene permisos para acceder al Panel',
      forgotPassword: 'Olvidaste tu contraseña?',
      iRememberMyPassword: 'Me acuerdo mi contraseña',
      recoverPasswordTitle: 'Recuperación de contraseña',
      recoverPasswordButton: 'Recuperar constraseña',
      recoverPasswordEmailSent: 'Revisa tu correo. Si esa cuenta existe, te enviamos un link para recuperar tu contraseña. Este enlace tiene una validez máxima de una hora. Si no puedes cambiar tu contraseña dentro de este periodo, entonces deberás solicitar el cambio una vez más.',
      changePasswordTitle: 'Cambio de contraseña',
      changePasswordButton: 'Cambiar contraseña',
      changePasswordSubmitted: 'Tu contraseña fue modificada',
      permissionsMissing: 'No tienes los permisos para acceder',
    },
    menu: {
      areas: 'Áreas',
      absences: 'Ausencias',
      admin: 'Administración',
      settings: 'Configuraciones',
      records: 'Registros',
      performance: 'Panel de performance',
      locations: 'Sucursales',
      notifications: 'Notificaciones',
      notifications_out_of_range: 'Incidentes',
      notifications_random_check: 'Check aleatorio',
      day_off_type: 'Tipos de día libre',
      day_off: 'Ausencias',
      collaborator: 'Colaboradores',
      daily_clock: 'Check IN/OUT',
      calendar: 'Calendario',
      log: 'Logs',
      company: 'Compañía',
      companyAdministrator: 'Administrador',
      performance_check: 'Check IN/OUT',
      daily_clock_notification: 'Check IN / Check OUT Notificaciones',
      logs: 'Logs',
      companies: 'Compañías',
      administrators: 'Administradores',
      holiday: 'Vacaciones',
      nonWorkingDays: 'Días no laborables',
      cafeteriaCalendar: 'Cafetería',
    },
    area: {
      name: 'Nombre',
      enabled: 'Habilitado',
      create: 'Crear área',
      edit: 'Editar área {{name}}',
      yes: 'Sí',
      no: 'No',
    },
    holidays: {
      addItem: 'Agregar fila',
      start: 'INICIO',
      end: 'FIN',
      days: 'DIAS',
      status: 'ESTADO',
      pending: 'PENDIENTES',
      ended: 'FINALIZADAS',
      inProgress: 'EN CURSO',
      successfullyUpdated: 'Las vacaciones fueron actualizadas exitosamente',
      successfullyDeleted: 'Las vacaciones fueron borradas exitosamente',
      fileImported: 'Archivo importado. Insertados: {{inserted}} | \n Existentes: {{exists}} | \n Fallos: {{failed}}',
    },
    daily_clock_notification: {
      id: 'ID',
      distance: 'Distancia',
      employee_id: 'Empleado',
      daily_clock_id: 'ID fichado',
      company_id: 'Compañía',
      title: 'Notificación de Check IN/OUT',
      date: 'Fecha',
      coordinates: 'Coordenadas',
      open_daily_clock: 'Ver Check IN/OUT',
      employee: 'Empleado',
      spoofedPicture: 'Falsicación'
    },
    random_daily_clock_notification: {
      message: 'Mensaje',
      lateBy: 'Tiempo demorado',
      date: 'Fecha',
      employee_id: 'Empleado',
      open_daily_clock: 'Ver Check IN/OUT',
    },
    office: {
      name: 'Nombre',
      address: 'Dirección',
      suburb: 'Barrio',
      password: 'Contraseña',
      city: 'Ciudad',
      country: 'País',
      zipCode: 'Código postal',
      location: 'Coordenadas',
      companyId: 'ID de compañía',
      employeeId: 'ID de empleado',
      create: 'Crear Sucursal',
      successfullyCreated: 'La sucursal fue creada exitosamente',
      successfullyUpdated: 'La sucursal fue actualizada exitosamente',
      successfullyDeleted: 'La sucursal fue borrada exitosamente',
      edit: 'Editar sucursal',
      generateNewPassword: 'Generar nueva contraseña',
      company: 'Compañia',
      latitud: 'Latitud',
      longitud: 'Longitud'
    },
    collaborator: {
      export: 'Exportar colaboradores',
      role: 'Rol',
      collaborator: 'Colaborador',
      area: 'Área',
      companyAdmin: 'Administrador',
      picture: 'Imagen',
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Email',
      deviceId: 'Id de dispositivo',
      approvalDate: 'Fecha de aprobado',
      withPerformance: 'Panel de performance',
      emailVerified: 'Email verificado',
      active: 'Activo',
      superior: 'Superior',
      create: 'Crear Colaborador',
      edit: 'Editar Colaborador {{name}}',
      document: 'Documento',
      nationalId: 'National ID',
      legajo: 'Número interno',
      password: 'Contraseña',
      repeatPassword: 'Repetir contraseña',
      homeOfficeAllowed: 'Permitido home office',
      address: 'Dirección',
      successfullyCreated: 'El colaborador fue creado exitosamente',
      successfullyUpdated: 'El colaborador fue actualizado exitosamente',
      successfullyDeleted: 'El colaborador fue borrado exitosamente',
      successfullyDisenrolled: 'El colaborador fue desenrolado exitosamente',
      deviceSuccessfullyUnlinked: 'El dispositivo fue blanqueado exitosamente',
      fileImported:
        'Archivo importado. Insertados: {{inserted}} | \n Existentes: {{exists}} | \n Fallos: {{failed}}',
      import: 'Importar colaboradores',
      templateName: 'Importador de colaboradores (template)',
      downloadTemplate: 'Descargar template de importador',
      homeOffice: 'Dirección de home office',
      birthDate: 'Fecha de nacimiento',
      citizenship: 'Ciudadanía',
      floor: 'Piso',
      apartment: 'Departamento',
      phoneNumber: 'Teléfono',
      admissionDate: 'Fecha de admisión',
      confirmNewTabChange: 'Hay modificaciones sin guardar, si cambia de solapa perderá los cambios',
      confirmNewTab: 'Formulario con cambios',
      personalDetails: 'Datos Personales',
      absences: 'Ausencias',
      calendars: 'Calendarios',
      holidays: 'Vacaciones',
      dailyClocks: 'Check IN/OUT',
    },
    calendar: {
      create: 'Crear Calendario',
      edit: 'Editar Calendario {{name}}',
      hourHasToBeMinorThan24: 'Este campo tiene que ser menor o igual que 23',
      minutesHasToBeMinorThan59: 'Este campo tiene que ser menor o igual que 59',
      startHour: 'Hora',
      startMinute: 'Minuto',
      endHour: 'Hora',
      endMinute: 'Minuto',
      pending: 'Pendiente',
      ended: 'Finalizado',
      inProgress: 'En progreso',
      addItem: 'Agregar fila',
      name: 'Nombre',
      type: 'Tipo',
      calendarId: 'Calendario',
      calendarName: 'Nombre de Calendario',
      types: {
        weekly: 'Semanal',
        fortnight: 'Bisemanal',
        flexible: 'Flexible',
        default: 'Compañía',
      },
      totalWorkHours: 'Horas Total',
      companyId: 'Compañía',
      active: 'Activo',
      activeSince: 'Activo desde',
      activeUntil: 'Activo hasta',
      fieldTooLong: 'El campo debe contener hasta 100 caracteres',
      successfullyCreated: 'El calendario fue creado exitosamente',
      successfullyUpdated: 'El calendario fue actualizado exitosamente',
      bulkSuccessfullyUpdated: 'Los calendarios fueron actualizados exitosamente',
      successfullyDeleted: 'El calendario fue borrado exitosamente',
      dateIsEmpty: 'Esta hora debe estar completa',
      startDateIsGrater: 'La hora de comienzo no puede ser mayor a la de fin',
      startDateIsEqual: 'Las horas no pueden ser iguales',
      days: 'Días',
      day: 'Día',
      start: 'Comienzo',
      end: 'Fin',
      Monday: 'Lunes',
      Tuesday: 'Martes',
      Wednesday: 'Miércoles',
      Thursday: 'Jueves',
      Friday: 'Viernes',
      Saturday: 'Sábado',
      Sunday: 'Domingo',
      totalHoursNotEqual:
        'La suma de las horas de los períodos ({{period}} horas) es diferente a las horas cargadas en el calendario ({{calendar}} horas)',
      week1: 'Primer semana',
      week2: 'Segunda semana',
      fileImported:
        'Archivo importado. Insertados: {{inserted}} | \n Existentes: {{exists}} | \n Fallos: {{failed}}',
      import: 'Importar Calendarios por colaborador',
      templateName: 'Importador de calendarios (template)',
      downloadTemplate: 'Descargar template de importador',
      activeSinceNotEditable: 'No se puede modificar fecha de inicio una vez creado',
      activeStatusNotEditable: 'No se puede modificar el estado active si la fecha de fin es anterior a hoy'
    },
    administrator: {
      create: 'Crear Administrador',
      edit: 'Editar Administrador "{{name}}"',
      document: 'Documento',
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Email',
      password: 'Contraseña',
      repeatPassword: 'Repetir contraseña',
      company: 'Compañía',
      picture: 'Imagen',
      legajo: 'Número interno',
      isCollaborator: 'Es colaborador?',
      isCollaboratorDescription: 'Los colaboradores pueden realizar Checks In/Out',
      successfullyCreated: 'El usuario administrador fue creado exitosamente',
      successfullyUpdated: 'El usuario administrador fue actualizado exitosamente',
      successfullyDeleted: 'El usuario administrador fue borrado exitosamente',
      successfullyDisenrolled: 'El usuario administrador fue desenrolado exitosamente',
      collaboratorData: 'Datos del colaborador',
    },
    dayOffType: {
      name: 'Nombre',
      payable: 'Pagable',
      category: 'Categoría',
      enabled: 'Habilitado',
      edit: 'Editar tipo de día libre',
      categories: {
        normal: 'Normal',
        sickness: 'Por enfermedad',
        other: 'Otro',
        vacations: 'Vacaciones'
      },
      successfullyUpdated: 'El tipo de día libre fue actualizado exitosamente'
    },
    dayOff: {
      date: 'Fecha',
      create: 'Crear Ausencia',
      edit: 'Editar ausencia {{type}}',
      files: 'Archivos',
      approvalDate: 'Fecha de aprobado',
      rejectedDate: 'Fecha de rechazo',
      approvedBy: 'Aprobado por',
      company: 'Compañía',
      employee: 'Empleado',
      reason: 'Razón',
      start: 'Comienzo',
      end: 'Fin',
      type: 'Tipo',
      importVacations: 'Importar Vacaciones',
      templateName: 'Importador de Vacaciones (template)',
      successfullyCreated: 'La ausencia fue creada exitosamente',
      successfullyUpdated: 'La ausencia fue actualizada exitosamente',
      successfullyDeleted: 'La ausencia fue borrada exitosamente',
      approveSuccessfully: 'La ausencia fue aprobada exitosamente',
      rejectSuccessfully: 'La ausencia fue rechazada exitosamente'
    },
    dailyClock: {
      type: 'Tipo',
      cafeteriaType: 'Cafeteria',
      types: {
        in: 'Entrada',
        out: 'Salida',
        randomCheck: 'Aleatorio',
        cafeteria: 'Cafeteria',
      },
      date: 'Fecha',
      maxDateMessage: 'La fecha no puede ser anterior a la seccionada',
      minDateMessage: 'La fecha no puede ser posterior a la seleccionada',
      time: 'Horario',
      picture: 'Foto',
      homeOffice: 'Home Office',
      userLocation: 'Coordenadas',
      location: 'Sucursal / HO',
      address: 'Dirección del fichado',
      viewInMap: 'Ver ubicación en Mapa',
      latitude: 'Latitud',
      longitude: 'Longitud',
      employee: 'Colaborador',
      recordType: 'Auditoría',
      recordTypes: {
        AdminCreateAction: 'Creado por admin',
        AdminEditAction: 'Editado por admin',
        System: 'Acción del sistema',
        UndoOnBoardingCheck: 'Acción del sistema',
        Mobile: 'Dispositivo móvil'
      },
      company: 'Compañía',
      create: 'Crear fichado',
      edit: 'Editar fichado {{type}}',
      successfullyCreated: 'Check in/out fue creado exitosamente',
      successfullyUpdated: 'Check in/out fue actualizado exitosamente'
    },
    log: {
      ipAddress: 'Dirección de IP',
      entity: 'Entidad',
      entityId: 'ID de entidad',
      actionType: 'Acción',
      userId: 'Usuario',
      companyId: 'Compañía',
      createdAt: 'Fecha de creación',
      previousData: 'Antes',
      updatedData: 'Después'
    },
    company: {
      create: 'Crear Compañía',
      edit: 'Editar Compañía "{{name}}"',
      successfullyCreated: 'Compañía creada exitosamente',
      successfullyUpdated: 'Compañía actualizada exitosamente',
      successfullyDeleted: 'Compañía borrada exitosamente',
      minTimeNotification: 'Tiempo mín. de notificaciones',
      maxTimeNotification: 'Tiempo máx. de notificaciones',
      checkInTime: 'Tiempo para hacer check in'
    },
    settings: {
      name: 'Nombre',
      logo: 'Logo',
      photo: 'Foto',
      mainColor: 'Color principal',
      accentColor: 'Color secundario',
      distanceRange: 'Rango de distancia (Home office)',
      defaultLanguage: 'Lenguaje principal',
      english: 'Inglés',
      spanish: 'Español',
      registrationCode: 'Código de registración',
      homeOfficeMode: 'Modo de Home Office',
      normal: 'Normal',
      intense: 'Intenso',
      none: 'Ninguno',
      businessName: 'Nombre de negocio',
      responsible: 'Responsable',
      email: 'Email',
      taxId: 'CUIT',
      phone: 'Teléfono',
      address: 'Dirección',
      taxAddress: 'Dirección de impuesto',
      bank: 'Banco',
      bankAccount: 'Cuenta bancaria',
      active: 'Activo',
      invoiceTypeId: 'Tipo de Factura',
      edit: 'Configuración',
      successfullyUpdated: 'La configuración fue actualizada exitosamente',
      dropzoneDetails: 'Tamaño de archivo entre 1MB y 5MB',
      minTimeNotification: 'Tiempo mín. de notificaciones',
      maxTimeNotification: 'Tiempo máx. de notificaciones',
      checkInTime: 'Tiempo para hacer check',
      intenseTooltip: 'Este modo le pedirá al usuario que realice random checks durante el día',
      minTimeNotificationTooltip: 'Tiempo mínimo desde el checkIn para generar una notificación push aleatoria',
      maxTimeNotificationTooltip: 'Tiempo máximo desde el checkIn para generar una notificación push aleatoria',
      checkInTimeTooltip: 'Cuenta regresiva para hacer check',
      fasActive: 'Anti Spoofing Activado',
      fasThreshold: 'Umbral',
      fasStrict: 'Modo estricto',
      ifSpoofedSave: 'Guardar'
    },
    validations: {
      required: 'Campo requerido',
      missingFields: 'Todos los campos son requeridos',
      minLength: 'Debe tener al menos {{min}} caracteres o más',
      maxLength: 'Debe tener {{max}} caracteres o menos',
      exactLength: 'Debe tener {{length}} caracteres',
      number: 'Debe ser un número',
      minValue: 'Debe ser mayor a {{min}}',
      maxValue: 'Debe ser menos a {{max}}',
      email: 'Dirección inválida',
      address: 'Dirección inválida',
      color: 'Color inválido',
      validText: 'Contiene caracteres inválidos',
      passwordsMatch: 'Las contraseñas no son iguales',
      startDateIsGrater: 'La hora de inicio debe ser menor a la de hora de fin',
      approvalDateIsGrater: 'La fecha de aprobación debe ser mayor o igual a la de fecha actual',
      invalidDate: 'Fecha inválida.',
      minTimeIsGreater: 'Tiempo max. tiene que ser mayor al min.',
      hourLowerThan24: 'Debe ser menor o igual a 23',
      minuteLowerThan59: 'Debe ser menor o igual a 59',
      greaterThan0: 'Debe ser mayor a 0',
      equalTimes: 'Tiempo Min. y Max. no pueden deben ser iguales ',
      dateNotEditable: "No se puede modificar la fecha de un Check, solamente los horarios son editables",
      dateLowerToday: 'La fecha debe ser menor a hoy.',
      dateTimeLowerNow: "No se permiten fechas/horarios futuros",
      dateGreaterToday: "La fecha debe ser mayor a hoy.",
    },
    dropzone: {
      attention: '¡Atención!',
      text: 'Arrastra una foto hasta aquí o haz click para subir',
      dropFilesHere: 'Suelte aquí sus archivos...',
      tooMuchFiles: 'Cantidad de archivos excedida',
    },
    menuDashboard: {
      dashboard: 'Panel de rendimiento',
      team: 'Mi equipo',
      people: 'Personas',
      report: 'Reportes',
      profile: 'Mi perfil'
    },
    table: {
      title: 'Eliminar filas.',
      text: '¿Desea eliminar las filas seleccionadas?',
      rowsPerPage: 'Filas por página',
      of: 'de',
      noData: 'No hay datos disponibles para mostrar',
      filters: 'Filtros',
    },
    nonWorkingDay: {
      create: 'Crear día no laborable',
      edit: 'Editar día no laborable {{title}}',
      title: 'Título',
      description: 'Descripción',
      start: 'Comienzo',
      end: 'Fin',
      category: 'Categoría',
      allDay: 'Día completo',
      day: 'Día',
      PublicHoliday: 'Feriado',
      BusinessClose: 'Compañía cerrada',
      successfullyCreated: 'Día no laborable creado exitosamente',
      successfullyUpdated: 'Día no laborable actualizado exitosamente',
      successfullyDeleted: 'Día no laborable borrado exitosamente',
    },
    cafeteriaCalendar: {
      create: 'Crear Turno Cafeteria',
      edit: 'Editar Turno Cafeteria {{name}}',
      name: 'Nombre',
      start: 'Comienzo',
      end: 'Fin',
      enabled: 'Habilitado',
      successfullyCreated: 'Turno Cafeteria creado exitosamente',
      successfullyUpdated: 'Turno Cafeteria actualizado exitosamente',
      successfullyDeleted: 'Turno Cafeteria borrado exitosamente',
    },
    performance: {
      id: 'ID',
      checkIn: 'Check In',
      checkOut: 'Check Out',
      actions: 'Acciones',
      myTeam: 'Mi equipo',
      name: 'Nombre',
      reason: 'Razón',
      directBoss: 'Jefe directo',
      me: 'Yo',
      team: 'Equipo',
      all: 'Todos',
      area: 'Área',
      absences: 'Ausencias',
      realTimeAssistance: 'Asistencia en tiempo real',
      onTime: 'A horario',
      late: 'Tarde',
      absent: 'Ausente',
      todayCheck: 'Check In / Check Out de hoy',
      reportedAbsences: 'Ausencias reportadas hoy',
      unreportedAbsences: 'Ausencias no reportadas hoy',
      time: 'Tiempo',
      hoursReport: 'Reporte de Horas',
      extraHours: 'Horas extra',
      missingHours: 'Horas faltantes',
      expectedCheckIn: 'CheckIn Esperado',
      expectedCheckOut: 'CheckOut Esperado',
      arrivedLate: 'Llegada tarde',
      leftEarly: 'Retiro temprano',
      checkInOut: 'Check in / Check out',
      cafeteria: 'Cafeteria',
      dailyClocks: 'Checks',
      filterBy: 'Filtrar por',
      month: 'Mes',
      currentMonth: 'Mes actual',
      oneMonth: 'Ultimo mes',
      twoMonths: 'Ultimos 2 meses',
      threeMonths: 'Ultimos 3 meses',
      week: 'Semana',
      dates: 'Fechas',
      fromDate: 'Desde',
      toDate: 'Hasta',
      today: 'Hoy',
      day: 'día',
      days: 'días',
      noData: 'Aún no hay datos',
      type: 'Tipo',
      notAssigned: 'Sin Área',
      onlyEmpty: 'Sin asistentes',
      reports: {
        download: 'Descargar',
        downloadAll: 'Descargar todos',
        downloadSelected: 'Descargar seleccionados',
        from: 'Desde',
        to: 'Hasta',
        orderBy: 'Ordenar Por',
        dateAsc: 'Fecha Ascendente',
        dateDesc: 'Fecha Descendente',
        nameAsc: 'Nombre Ascendente',
        nameDesc: 'Nombre Descendente',
        date: 'Fecha',
        expectedWorkHours: 'Horas trabajables',
        totalWorkedHours: 'Horas trabajadas',
        dataError: "Ups! No hay información para mostrar",
      },
      none: 'Ninguno',
      profile: {
        personalDataTitle: 'Mis Datos Personales',
        address: 'Dir',
        phone: 'Tel',
        email: 'E-mail',
        myCalendarTitle: 'Mi Calendario',
        myHOTitle: 'Mi Direccion de Home Office',
        myVacationsTitle: 'Mis Vacaciones',
        availableVacations: 'DÍAS DISPONIBLES',
      },
      dashboard: {
        attend: 'Presente',
        absence: 'Ausente',
        late: 'Tarde',
        homeOffice: 'Trabajo Remoto',
        inSite: 'Trabajo en sitio',
        directBoss: 'Jefe directo',
        calendarTitle: 'CALENDARIO',
        checkInOutTitle: 'CHECK IN/CHECK OUT',
        checkInTitle: 'CHECK IN',
        checkOutTitle: 'CHECK OUT',
        attendanceVsAbsences: 'Asistencias vs Ausencias',
        homeOfficeVsInsite: 'Trabajo remoto vs Trabajo en sitio',
        hoursMissedDueToLateArrivals: 'Horas perdidas por llegadas tarde',
        hours: 'Horas',
        averageWorkday: 'Jornada laboral promedio',
        absencesQty: 'Cantidad de inasistencias',
        days: 'Días',
        totalWorkedHoursVsExpectedWorkHours: 'Hs trabajadas vs Hs trabajables',
      },
    },
    roles: {
      collaborator: 'Colaborador',
      companyAdministrator: 'Administrador',
    },
    filters: {
      all: 'Todos',
      team: 'Equipo',
      peopleFilter: 'Filtrar por',
    },
  },
};
